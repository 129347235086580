<template>
	<div class="support-smb-lnb" :class="className">
		<button type="button" class="button-support-smb" @click.prevent="toggleLnb()"><i class="icon-nav-list">메뉴 열기</i></button>
		<nav class="support-smb-nav" :class="{ 'is-active': showLnb }">
			<header class="support-smb-nav__header">
				<h3>2024년 소상공인 온라인 판로지원사업</h3>
				<button type="button" class="button-close" @click.prevent="toggleLnb()"><i class="icon-close">창닫기</i></button>
			</header>
			<div class="support-smb-nav__body">
				<ul>
					<li v-for="(item, idx) in items" :key="idx">
						<a href="#" @click.prevent="expandMenu(idx)">{{ item.text }}</a>
						<transition name="slide">
							<div v-if="item.menuList && item.expand">
								<ul>
									<li v-for="(menu, idx2) in item.menuList" :key="idx2" :class="{ mt1: menu.to == '' && idx2 > 0 }">
										<router-link v-if="menu.to" :to="menu.to">{{ menu.text }}</router-link>
										<span v-else class="text">{{ menu.text }}</span>
										<ul v-if="menu.menuList">
											<li v-for="(subMenu, idx3) in menu.menuList" :key="idx3">
												<router-link :to="subMenu.to">{{ subMenu.text }}</router-link>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</transition>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</template>
<script>
export default {
	name: 'LnbSupportSmbSales',
	props: {
		className: String,
		openVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data: () => ({
		showLnb: true,
		items: [
			{
				text: '역량강화',
				expand: true,
				menuList: [
					{
						text: '소상공인 인플루언서 교육',
						to: '/support/smb-sales/list',
					},
					{
						text: '상품 개선 지원',
						to: '/support/smb-sales/list',
					},
					{
						text: '전담셀러 매칭지원',
						to: '/support/smb-sales/list',
					},
					{
						text: '콘텐츠 제작지원',
						to: '/support/smb-sales/list',
					},
					{
						text: '우수제품 홍보ㆍ광고지원',
						to: '/support/smb-sales/list',
					},
					{
						text: '소상공인 진단 및 패키지',
						to: '/support/smb-sales/list',
					},
				],
			},
			{
				text: '채널진출',
				expand: true,
				menuList: [
					{
						text: '온라인쇼핑몰 판매지원',
						to: '',
						menuList: [
							{
								text: '온라인몰 입점판매',
								to: '/support/smb-sales/list',
							},
							{
								text: '자사몰 구축지원',
								to: '/support/smb-sales/list',
							},
						],
					},
					{
						text: '글로벌시장 지원',
						to: '',
						menuList: [
							{
								text: '해외몰 입점지원',
								to: '/support/smb-sales/list',
							},
							{
								text: '글로벌 패키지',
								to: '/support/smb-sales/list',
							},
						],
					},
					{
						text: 'TV홈쇼핑 및 T커머스 입점지원',
						to: '/support/smb-sales/list',
					},
					{
						text: '미디어커머스 입점지원',
						to: '/support/smb-sales/list',
					},
					{
						text: '라이브커머스 제작ㆍ운영 지원',
						to: '/support/smb-sales/list',
					},
					{
						text: '정기구독 지원',
						to: '/support/smb-sales/list',
					},
					{
						text: 'O2O 융합 판매ㆍ기획전',
						to: '/support/smb-sales/list',
					},
					{
						text: '스마트물류 및 사후관리',
						to: '',
						menuList: [
							{
								text: '물류지원',
								to: '/support/smb-sales/list',
							},
							{
								text: '사후관리',
								to: '/support/smb-sales/list',
							},
						],
					},
				],
			},
			{
				text: '기반조성',
				expand: true,
				menuList: [
					{
						text: '온라인 판로진출 플랫폼',
						to: '/support/smb-sales/list',
					},
					{
						text: '디지털커머스전문기관',
						to: '/support/smb-sales/list',
					},
					{
						text: '스마트 플래그십 스토어 구축ㆍ운영',
						to: '/support/smb-sales/list',
					},
				],
			},
		],
	}),
	mounted() {
		this.showLnb = this.openVisible;
	},
	methods: {
		toggleLnb() {
			this.showLnb = !this.showLnb;
		},
		expandMenu(el) {
			this.items[el].expand = !this.items[el].expand;
		},
	},
};
</script>

<style>
.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
	max-height: 1000px;
	overflow: hidden;
}

.slide-enter,
.slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
